<template>
  <div class="repair-area-wrapper flex">
    <div class="search-content searchBox">
      <div class="search-item">
        <label>姓名</label>
        <el-input type="text" v-model="filter.name" placeholder="请输入" size="medium" clearable></el-input>
      </div>
      <div class="search-item">
        <label>状态</label>
        <el-select v-model="filter.status" size="medium" placeholder="请选择" clearable>
            <el-option  label="启用" value="true"></el-option>
            <el-option  label="禁用" value="false"></el-option>
        </el-select>
      </div>
      <div class="search-item button-group pdl16">
        <el-button type="primary" class="btn-search" @click="search()" size="medium">查询</el-button>
        <el-button  class="btn-reset" @click="resetSearch" size="medium">重置</el-button>
      </div>
    </div>
    <div class="table-content inline-flex contentBox">
      <div class="contentTool">
        <el-button @click="addWorker" class=" float-right mgr10" type="primary" size="medium">新增</el-button>
      </div>
      <el-table :data="tableData" class="contentTable" size="medium" row-key="id" :tree-props="{children:'child',hasChildren:'hasChildren'}">
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="mobile" label="联系电话"></el-table-column>
        <el-table-column prop="finishOrderCount" label="总单量"></el-table-column>
        <el-table-column prop="totalEvaluationScore" label="评估值"></el-table-column>
        <el-table-column prop="createTime" label="加入时间"></el-table-column>
        <el-table-column prop="status" label="工人状态">
          <template slot-scope="{row}">
            <span :class="[row.status?'on':'close']">
              {{row.status?'启用':'禁用'}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="canReceiveOrder" label="工作状态">
          <template slot-scope="scope">
            <span :class="[scope.row.canReceiveOrder?'on':'close']">
              {{scope.row.canReceiveOrder?'接单':'休息'}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="processOrderCount" label="工单状态">
          <template slot-scope="scope">
              <span :class="[scope.row.processOrderCount>0?'blue':'colorRed']">
                {{scope.row.processOrderCount>0?`已接单-${scope.row.processOrderCount}`:'-'}}
              </span>
          </template>
        </el-table-column>
        <el-table-column  label="操作">
          <template  slot-scope="scope">
            <span class="mgr10 blue" @click="editWorker(scope.row.id)">编辑</span>
            <span class="mgr10 blue" @click="changeWorkerStatus(scope.row.id,!scope.row.status)">{{scope.row.status?'禁用':'启用'}}</span>
          </template>
        </el-table-column>
      </el-table>
      <reconova-pagination @paginationChange="paginationChange" :page-no="pageIndex" :page-size="pageSize" :total="total"></reconova-pagination>
    </div>
    <div class="dialog-wrapper">
      <edit v-if="showEditModal" :show.sync="showEditModal" :edit.sync="editData"></edit>
    </div>
  </div>
</template>

<script>
import ReconovaPagination from "@/components/reconova/reconovaPagination";
import Edit from "./edit";
import pagination from "@/mixins/pagination";
export default {
  name: "maintenance-staff",
  components: {
    Edit,
    ReconovaPagination
  },
  mixins:[pagination],
  data(){
    return {
      filter:{
        name:'',
        status:''
      },
      showEditModal:false,
      editData:{},
      tableData:[]
    }
  },
  mounted(){
    this.search()
  },
  methods:{

    // 查询
    search(pageSize=10,pageIndex=1){
      let requestParam = this.getParams(pageSize,pageIndex);
      this.$api.property.getRepairUserList(requestParam).then(response=>{
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.pageIndex = response.data.pageNum;
        this.pageSize = response.data.pageSize;
      })
    },

    //  新增
    addWorker(){
      this.showEditModal = true;
      this.editData={
        id:'',
        status:true,
        hasAllType:false,
        canReceiveOrder:false,
        areas:[]
      }
    },

    editWorker(id){
      this.editData = {
        id
      };
      this.showEditModal = true;
    },

    changeWorkerStatus(id,status){
      this.$api.property.operatorWorker({id,status}).then(res=>{
        this.$message.success('操作成功');
        this.search();
      })

    }
  }
}
</script>

<style scoped lang="scss">
.el-input,.el-select {
  width: 220px;
}
.el-table{
  flex: 1;
  overflow-y: auto;
}
.on{
  color:$subjectColor;
}
.close{
  color:red;
}
</style>